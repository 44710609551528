import axios from "axios"
const apiUrl = process.env.REACT_APP_API_URL

async function multiAuthLogin(email, access_token, data, navigate, setToken) {
  return axios
    .post(apiUrl.concat("multiauth"), { email, access_token, data })
    .then((res) => {
      if (res.data.access_token) {
        caches.open("access").then((cache) => {
          cache.put(email, new Response(JSON.stringify(res.data.access_token)))
        })
        setToken(res.data.access_token)
        navigate("/", {
          state: {
            token: res.data.access_token,
            email: res.data.email,
          },
        })
      } else {
        return {
          alert: true,
          msg: "Wrong Passcode, please check your email.",
        }
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

async function multiAuthSendAnother(email) {
  return axios.post(apiUrl.concat("anotherEmail"), { email }).then((res) => {
    return res
  })
}

async function loginPost(
  email,
  password,
  captcha,
  setToken,
  navigate,
  getCache
) {
  let access_granted = false
  let awaited_response = await getCache("access", email)
  if (awaited_response) {
    access_granted = true
  }
  return axios
    .post(apiUrl.concat("login"), { email, password, captcha, access_granted })
    .then((res) => {
      if (res.data.captcha_verification === false) {
        return {
          alert: true,
          msg: "ReCaptcha failed.",
        }
      }
      if (res.data.account_found) {
        if (res.data.login) {
          if (res.data.no_otp_cookie === true) {
            navigate("/multiauth", {
              state: {
                token: res.data.access_token,
                email: res.data.email,
              },
            })
          } else {
            setToken(res.data.access_token)
            navigate("/", {
              state: {
                token: res.data.access_token,
                email: res.data.email,
                user_role: res.data.user_role,
              },
            })
          }
        } else {
          //Wrong Password
          return {
            alert: true,
            msg: "Wrong password",
          }
        }
      } else {
        //Account not found. Need to sign up.
        return {
          alert: true,
          msg: "No account found.",
        }
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

function googleLogin(data, setToken, navigate) {
  return axios
    .post(apiUrl + "login", data)
    .then((res) => {
      if (res.data.account_found) {
        //Account found.
        if (res.data.login) {
          //Login Successful
          setToken(res.data.access_token)
          navigate("/", { token: res.data.access_token })
        } else {
          //Wrong Password
          return {
            alert: true,
            msg: "Wrong password",
          }
        }
      } else {
        //Account not found. Sign up with google.
        axios.post(apiUrl + "signup", data).then((res) => {
          if (res.data.user_added === true) {
            axios
              .post(apiUrl + "login", {
                email: data.email,
                pass: data.pass,
                googleLogin: true,
              })
              .then((res) => {
                setToken(res.data.access_token)
                navigate("/", { token: res.data.access_token })
              })
          } else {
            return {
              alert: true,
              msg: "Error Signing up with Google",
            }
          }
        })
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

async function otpSendPost(data) {
  return axios
    .post(apiUrl.concat("send-phone-otp"), data)
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      // Connection Error with Server
      return {
        alert: true,
        msg: "Error connecting with server",
      }
    })
}

async function otpVerifyPost(data, setToken, navigate) {
  return axios
    .post(apiUrl + "verify-phone-otp", data)
    .then((res) => {
      if (res.data.otp_verified && !res.data.otp_expired) {
        setToken(res.data.access_token)
        navigate("/", { token: res.data.access_token })
      } else {
        return res
      }
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}

function signUpPost(data, captcha, setToken, navigate) {
  return axios
    .post(apiUrl + "signup", { data, captcha })
    .then((res) => {
      if (
        "captcha_verification" in res.data &&
        res.data.captcha_verification === false
      ) {
        return {
          alert: true,
          msg: "ReCaptcha failed.",
        }
      }
      if (res.data.user_added === true) {
        setToken(res.data.access_token)
        navigate("/", { token: res.data.access_token })
      } else {
        return {
          alert: true,
          msg: res.data.error,
        }
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

function getData(endpoint, removeToken, clearCache, navigate, retry = false) {
  return axios
    .get(apiUrl + endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      // retry=true to trigger reload the page on success
      if (retry) {
        window.location.reload()
      } else {
        return res.data
      }
    })
    .catch((err) => {
      if (
        err?.response?.status === 401 &&
        localStorage.getItem("token") !== null
      ) {
        window.alert("Session timeout. Please sign in again.")
        removeToken()
        clearCache()
        localStorage.clear()
        sessionStorage.clear()
        navigate("/")
      } else {
        // if error is not 401, retry the request after 1s
        setTimeout(() => {
          getData(endpoint, removeToken, clearCache, navigate, true)
        }, 1000)
      }
    })
}

function resetPassPost(email, captcha) {
  return axios
    .post(apiUrl.concat("reset-password"), { email, captcha })
    .then((res) => {
      return res
    })
    .catch((err) => console.log(err))
}

function changeUserDetailsPost(details) {
  return axios
    .post(
      apiUrl.concat("update-profile"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function changePasswordPost(password, resetToken) {
  return axios
    .post(
      apiUrl.concat("change-password"),
      { password },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.log(err)
    })
}

function resetPasswordPost(password) {
  return axios
    .post(apiUrl.concat("reset-password-post"), { password })
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.log(err)
    })
}

function microsoftLogin(data, setToken, navigate) {
  return axios
    .post(apiUrl + "login", data)
    .then((res) => {
      if (res.data.account_found) {
        //Account found.
        if (res.data.login) {
          //Login Successful
          setToken(res.data.access_token)
          navigate("/", { token: res.data.access_token })
        } else {
          //Wrong Password
          return {
            alert: true,
            msg: "Wrong password",
          }
        }
      } else {
        //Account not found. Sign up with Microsoft.
        axios.post(apiUrl + "signup", data).then((res) => {
          if (res.data.user_added === true) {
            axios
              .post(apiUrl + "login", {
                email: data.email,
                pass: data.pass,
                msLogin: true,
              })
              .then((res) => {
                setToken(res.data.access_token)
                navigate("/", { token: res.data.access_token })
              })
          } else {
            return {
              alert: true,
              msg: "Error Sigining up with Microsoft",
            }
          }
        })
      }
    })
    .catch((err) => {
      console.error(err)
    })
}

function addProviderElectricityRatePost(details) {
  return axios
    .post(
      apiUrl.concat("add-provider-electricity-rate"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function editProviderElectricityRatePost(details) {
  return axios
    .post(
      apiUrl.concat("edit-provider-electricity-rate"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function deleteProviderElectricityRatePost(details) {
  return axios
    .post(
      apiUrl.concat("delete-provider-electricity-rate"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function getAllProviders() {
  return axios
    .get(apiUrl.concat("get-providers"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function getAllProviderRateCategories() {
  return axios
    .get(apiUrl.concat("get-providers-rate-categories"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function addProviderElectricityBasicChargePost(details) {
  return axios
    .post(
      apiUrl.concat("add-provider-electricity-basic-charge"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function editProviderElectricityBasicChargePost(details) {
  return axios
    .post(
      apiUrl.concat("edit-provider-electricity-basic-charge"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function deleteProviderElectricityBasicChargePost(details) {
  return axios
    .post(
      apiUrl.concat("delete-provider-electricity-basic-charge"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function addCityElectricityRatePost(details) {
  return axios
    .post(
      apiUrl.concat("add-city-electricity-rate"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function getAllProvinces() {
  return axios
    .get(apiUrl.concat("get-provinces"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function getAllCities() {
  return axios
    .get(apiUrl.concat("get-cities"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function deleteCityRatesPost(details) {
  return axios
    .post(
      apiUrl.concat("delete-city-rates"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function editCityElectricityRatePost(details) {
  return axios
    .post(
      apiUrl.concat("edit-city-electricity-rate"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function addProviderGasRatePost(details) {
  return axios
    .post(
      apiUrl.concat("add-provider-gas-rate"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function editProviderGasRatePost(details) {
  return axios
    .post(
      apiUrl.concat("edit-provider-gas-rate"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function deleteProviderGasRatePost(details) {
  return axios
    .post(
      apiUrl.concat("delete-provider-gas-rate"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function addProviderGasBasicChargePost(details) {
  return axios
    .post(
      apiUrl.concat("add-provider-gas-basic-charge"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function editProviderGasBasicChargePost(details) {
  return axios
    .post(
      apiUrl.concat("edit-provider-gas-basic-charge"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function deleteProviderGasBasicChargePost(details) {
  return axios
    .post(
      apiUrl.concat("delete-provider-gas-basic-charge"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function addCityGasRatePost(details) {
  return axios
    .post(
      apiUrl.concat("add-city-gas-rate"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function editCityGasRatePost(details) {
  return axios
    .post(
      apiUrl.concat("edit-city-gas-rate"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function changeECMIsDone(details) {
  return axios
    .post(
      apiUrl.concat("change-ECM-is-done"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function changeECMYearOfImplementation(details) {
  return axios
    .post(
      apiUrl.concat("change-ECM-year-of-implementation"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

function getECMTableData() {
  return axios
    .get(apiUrl.concat("get_ecm_table_data"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function createTableWithUploadFile(formData) {
  return axios
    .post(apiUrl.concat("create-table-with-upload-file"), formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.log(err)
    })
}

async function updateOutputTable(
  projectId,
  tableName,
  rowIndex,
  cellIndex,
  inputValue
) {
  console.log(
    "Received edited value",
    projectId,
    tableName,
    rowIndex,
    cellIndex,
    inputValue
  )

  return axios
    .post(
      apiUrl.concat("update-output-table"),
      { projectId, tableName, rowIndex, cellIndex, inputValue },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      if (res.data.message) {
        console.log("Values received by backend successfully")
      }
      return res
    })
    .catch((err) => {
      console.log(err)
      return err
    })
}

async function getAllUsers() {
  return axios
    .get(apiUrl.concat("get-all-users"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function getAllUserProjects(account_number) {
  return axios
    .get(apiUrl.concat("get-all-user-projects"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { account_number: account_number },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function getAllProjectsButUser(account_number) {
  return axios
    .get(apiUrl.concat("get-all-projects-but-user"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { account_number: account_number },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function addUserProjects(details) {
  return axios
    .post(
      apiUrl.concat("add-user-projects"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

async function removeUserProjects(details) {
  return axios
    .post(
      apiUrl.concat("remove-user-projects"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

async function getAllRegisteredProjects() {
  return axios
    .get(apiUrl.concat("get-all-registered-projects"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function getAllProjectUsers(project_number) {
  return axios
    .get(apiUrl.concat("get-all-project-users"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { project_number: project_number },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function getAllUsersButProject(project_number) {
  return axios
    .get(apiUrl.concat("get-all-users-but-project"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { project_number: project_number },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function getHistorical_GHG_EmissionData(startYear, numOfYears) {
  return axios
    .post(
      apiUrl.concat("get-ghg-emission-reduction-data"),
      {
        start_year: startYear,
        number_of_years: numOfYears,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

async function addEquipment(details) {
  return axios
    .post(
      apiUrl.concat("add-equipment"),
      {
        project_id: details.project_id,
        equipment_type:
          details.equipment_type.length === 0 ? "-" : details.equipment_type,
        quantity: isNaN(details.quantity) ? null : parseInt(details.quantity),
        year_of_equipment: isNaN(details.year_of_equipment)
          ? null
          : parseInt(details.year_of_equipment),
        make_and_model: details.make_and_model,
        identifier_tag: details.identifier_tag,
        end_use: details.end_use,
        area_served: details.area_served,
        fuel: details.fuel,
        electrical_power_rating: isNaN(details.electrical_power_rating)
          ? null
          : parseFloat(details.electrical_power_rating),
        gas_power_rating: isNaN(details.gas_power_rating)
          ? null
          : parseFloat(details.gas_power_rating),
        unit: details.unit,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

async function deleteEquipment(equipment_id, project_id) {
  return axios
    .post(
      apiUrl.concat("delete-equipment"),
      {
        equipment_id: equipment_id,
        project_id: project_id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

async function calculateECMBundle(ecm_identifiers) {
  return axios
    .get(apiUrl.concat("calculate-ecm-bundle"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { ecm_identifiers: ecm_identifiers },
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err)
    })
}

async function calculateDecarbPlan(details) {
  return axios
    .post(apiUrl.concat("analyze-decarb-plan"), details, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function importAuditReports(files) {
  return axios
    .post(apiUrl.concat("import-PDF-reports"), files, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data)
}

async function removeHomeProjects(details) {
  return axios
    .post(
      apiUrl.concat("remove-projects"),
      { details },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function updateHomeProjects(files) {
  return axios
    .post(apiUrl.concat("update-projects"), files, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function getSiteInputOptions() {
  return axios
    .get(apiUrl.concat("get-site-input-options"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function getEspmPropertyTypes() {
  return axios
    .get(apiUrl.concat("get-ESPM-property-types"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function saveDraft(details) {
  return axios
    .post(apiUrl.concat("save-property-draft"), details, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function loadDrafts() {
  return axios
    .get(apiUrl.concat("load-user-property-drafts"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function submitDraft(details) {
  return axios
    .post(apiUrl.concat("submit-property-draft"), details, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function getDraftById(draft_id) {
  return axios
    .get(apiUrl.concat("load-property-draft-by-id"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { draft_id: draft_id },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

async function discardDraft(draft_id) {
  return axios
    .get(apiUrl.concat("discard-property-draft-by-id"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { draft_id: draft_id },
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

async function save_update_decarb_plan(plan_object) {
  return axios
    .post(apiUrl.concat("save-decarb-plan"), plan_object, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

async function delete_decarb_plan(plan_id) {
  return axios
    .post(
      apiUrl.concat("delete-decarb-plan"),
      { plan_id: plan_id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

async function deleteUserOnAdmin(user_email) {
  return axios
    .post(
      apiUrl.concat("delete-user-on-admin"),
      { user_email: user_email },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

async function deleteProjectOnAdmin(project_number) {
  return axios
    .post(
      apiUrl.concat("delete-project-on-admin"),
      { project_number: project_number },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

async function getESPM_Connection() {
  return axios
    .get(apiUrl.concat("ESPM-get-connection"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

async function acceptESPM_ConnectionRequest(espm_account_id) {
  return axios
    .post(
      apiUrl.concat(
        "ESPM-accept-pending-connection?account_id=" + espm_account_id
      ),
      null,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

async function disconnectESPM_Account(espm_account_id) {
  return axios
    .post(
      apiUrl.concat("ESPM-disconnect-account?account_id=" + espm_account_id),
      null,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

async function getESPM_PendingProperties(espm_account_id) {
  return axios
    .get(
      apiUrl.concat(
        "ESPM-get-pending-properties?account_id=" + espm_account_id
      ),
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

async function importESPM_Properties(espm_account_id, espm_property_ids) {
  return axios.post(
    apiUrl.concat("ESPM-import-properties"),
    { account_id: espm_account_id, property_ids: espm_property_ids },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  )
}

async function loadESPM_Settings() {
  return axios.get(apiUrl.concat("ESPM-load-settings"), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}

async function saveESPM_Settings(user_name, password, sync_interval) {
  return axios.post(
    apiUrl.concat("ESPM-save-settings"),
    {
      user_name: user_name,
      password: password,
      sync_interval: sync_interval,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  )
}

async function updatePowerBIUserEmail(pbi_user_email) {
  return axios.post(
    apiUrl.concat("update-powerbi-user-email"),
    { pbi_user_email: pbi_user_email },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  )
}

async function getSavedPowerBiUserReport(pbi_user_email) {
  return axios.get(apiUrl.concat("get-powerbi-user-report"), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    params: { pbi_user_email: pbi_user_email },
  })
}

async function insertPowerBIUserReport(
  pbi_user_email,
  workspace_id,
  workspace_name,
  report_id,
  report_name
) {
  return axios.post(
    apiUrl.concat("insert-powerbi-user-report"),
    {
      pbi_user_email: pbi_user_email,
      workspace_id: workspace_id,
      workspace_name: workspace_name,
      report_id: report_id,
      report_name: report_name,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  )
}

async function updatePowerBIUserReport(
  pbi_user_email,
  workspace_id,
  workspace_name,
  report_id,
  report_name
) {
  return axios.post(
    apiUrl.concat("update-powerbi-user-report"),
    {
      pbi_user_email: pbi_user_email,
      workspace_id: workspace_id,
      workspace_name: workspace_name,
      report_id: report_id,
      report_name: report_name,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  )
}

async function deletePowerBIUserReport(pbi_user_email) {
  return axios.post(
    apiUrl.concat("delete-powerbi-user-report"),
    {
      pbi_user_email: pbi_user_email,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  )
}

async function getPowerBIUserEmails() {
  return axios.get(apiUrl.concat("get-powerbi-user-emails"), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
}

async function importFrescoBuildings(input_files, db_tag) {
  let files_data = new FormData()
  for (let file of input_files) {
    files_data.append("input_files", file.file_blob, file.file_name)
  }
  files_data.append("db_tag", db_tag)

  return axios
    .post(apiUrl.concat("fresco-import-buildings"), files_data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data)
}

async function getImportFrescoBuildingsStatus(job_id) {
  return axios
    .get(apiUrl.concat("fresco-get-import-buildings-status"), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
      params: { job_id: job_id }
    })
    .then((res) => res.data)
}

export {
  microsoftLogin,
  loginPost,
  googleLogin,
  signUpPost,
  getData,
  resetPassPost,
  changeUserDetailsPost,
  resetPasswordPost,
  changePasswordPost,
  multiAuthLogin,
  multiAuthSendAnother,
  otpSendPost,
  otpVerifyPost,
  addProviderElectricityRatePost,
  editProviderElectricityRatePost,
  deleteProviderElectricityRatePost,
  getAllProviders,
  getAllProviderRateCategories,
  addProviderElectricityBasicChargePost,
  editProviderElectricityBasicChargePost,
  deleteProviderElectricityBasicChargePost,
  getAllProvinces,
  getAllCities,
  addCityElectricityRatePost,
  editCityElectricityRatePost,
  deleteCityRatesPost,
  addProviderGasRatePost,
  editProviderGasRatePost,
  deleteProviderGasRatePost,
  addProviderGasBasicChargePost,
  editProviderGasBasicChargePost,
  deleteProviderGasBasicChargePost,
  addCityGasRatePost,
  editCityGasRatePost,
  changeECMIsDone,
  changeECMYearOfImplementation,
  createTableWithUploadFile,
  updateOutputTable,
  getECMTableData,
  getAllUsers,
  getAllUserProjects,
  getAllProjectsButUser,
  addUserProjects,
  removeUserProjects,
  getAllRegisteredProjects,
  getAllProjectUsers,
  getAllUsersButProject,
  getHistorical_GHG_EmissionData,
  addEquipment,
  deleteEquipment,
  calculateECMBundle,
  calculateDecarbPlan,
  importAuditReports,
  removeHomeProjects,
  updateHomeProjects,
  getSiteInputOptions,
  getEspmPropertyTypes,
  saveDraft,
  loadDrafts,
  submitDraft,
  getDraftById,
  discardDraft,
  save_update_decarb_plan,
  delete_decarb_plan,
  deleteUserOnAdmin,
  deleteProjectOnAdmin,
  getESPM_Connection,
  acceptESPM_ConnectionRequest,
  disconnectESPM_Account,
  getESPM_PendingProperties,
  importESPM_Properties,
  loadESPM_Settings,
  saveESPM_Settings,
  updatePowerBIUserEmail,
  getSavedPowerBiUserReport,
  insertPowerBIUserReport,
  updatePowerBIUserReport,
  deletePowerBIUserReport,
  getPowerBIUserEmails,
  importFrescoBuildings,
  getImportFrescoBuildingsStatus
}
