import { useState, useEffect, useRef } from "react"
import FileUploaderModal from "../global/components/fileUploader/FileUploaderModal"
import FileDetailsTable from "../global/components/fileUploader/FileDetailsTable"
import InstructionalText from "../global/components/InstructionalText/InstructionalText"
import Button from "../global/components/button/Button"
import "./importBuildings.css"
import {
  importFrescoBuildings,
  getImportFrescoBuildingsStatus,
} from "../../utils/HttpClient"
import Select from "../global/components/select/Select"
import { LinearProgress } from "@mui/material"
import { parseFloatAndFormat } from "../../utils/DataFormatter"
import Table from "../global/components/Table/Table"
import { Navigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const apiUrl = process.env.REACT_APP_API_URL

function ImportBuildings({ user_data, building_schema }) {
  // building schema - identifier for destination
  const [buildingSchema, setBuildingSchema] = useState(building_schema)

  // user data - use to confirm user access
  const [userData, setUserData] = useState(user_data)

  // list of uploaded files - for the session
  const [sessionFilesList, setSessionFilesList] = useState([])

  // progress state
  const [jobID, setJobID] = useState("")
  const [progressValue, setProgressValue] = useState(0)
  const [jobCompleted, setJobCompleted] = useState(false)
  const [statusText, setStatusText] = useState("")
  const [showProgress, setShowProgress] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [processedBuildingsList, setProcessedBuildingsList] = useState([])
  const [errorBuildingsList, setErrorBuildingsList] = useState([])

  // file uploader modal ref
  const file_uploader_modal = useRef(null)

  const [db_tag, setDBTag] = useState("Mixed DB")
  const db_tags = ["Mixed DB", "RAP and CEAP", "BC Assessments"]

  // remove file from list handler
  function removeFileFromListHandler(index) {
    let new_list = [...sessionFilesList]
    new_list.splice(index, 1)
    setSessionFilesList(new_list)
  }

  function handleOnDownloadFile(file) {
    let url = ""
    if (file.file_blob instanceof File) {
      const fileType = file.file_blob.type || "application/octet-stream"
      // Create a URL for the blob object
      url = window.URL.createObjectURL(
        new Blob([file.file_blob], { type: fileType })
      )
    } else {
      url = apiUrl + file.file_path
    }

    // Create an anchor element (`<a>`) for the download
    const link = document.createElement("a")

    // Set the download attribute with the filename
    link.setAttribute("download", file.file_name)

    // Hide the element
    link.style.display = "none"

    // Set the href of the link to the blob URL
    link.href = url

    // Append the link to the body
    document.body.appendChild(link)

    // Programmatically trigger the click event
    link.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }, 100) // Delay cleanup by 100ms or more if needed
  }

  function selectFilehandler(files) {
    setSessionFilesList(files)
  }

  // call start job API
  // if successful, show job started message
  // call status API every 2 seconds for job status
  // when successful stop loader and show results
  // if failed show error message
  function startImportJobHandler() {
    console.log("startImportJobHandler")
    if (sessionFilesList.length !== 0) {
      importFrescoBuildings(sessionFilesList, db_tag)
        .then((res) => {
          if (res["job_id"]) {
            console.log("Job ID", res["job_id"])
            setJobID(res["job_id"])
            setShowProgress(true)
            setShowErrors(false)
          } else {
            // server error
            alert("Server Error: job did not start, job ID is not assigned!")
          }
        })
        .catch((err) => {
          //show error message
          if (err.status === 401) {
            Navigate("/")
          }
          console.log(err)
        })
    } else {
      alert("Please attach files to upload to the server!")
    }
  }

  // check for job status,
  // if in progress, update progress bar
  // if done, show results and errors
  useEffect(() => {
    if (jobID) {
      let interval = setInterval(() => {
        getImportFrescoBuildingsStatus(jobID)
          .then((res) => {
            if (res["status"] === "In Progress") {
              setProgressValue(parseFloat(res["progress"]) * 100)
            } else if (res["status"] === "Done") {
              clearInterval(interval)
              // show results
              setShowProgress(false)
              // show errors
              setShowErrors(true)
              if (res["buildings"]) {
                setProcessedBuildingsList(
                  transformArrayToTableData(res["buildings"])
                )
              }
              if (res["warnings_and_errors"]) {
                setErrorBuildingsList(
                  transformArrayToTableData(res["warnings_and_errors"])
                )
              }
              setJobCompleted(true)
              setStatusText("Job successfully completed!")
            } else {
              clearInterval(interval)
              setProgressValue(0)
              setProcessedBuildingsList([])
              setErrorBuildingsList([])
              setShowProgress(false)
              setShowErrors(true)
              setJobCompleted(false)
              setStatusText("Server Error: Job ID not found!")
            }
          })
          .catch((err) => {
            console.log(
              "Error in getting job status, check Job ID and server status"
            )
            console.log(err)
            clearInterval(interval)
            setProcessedBuildingsList([])
            setErrorBuildingsList([])
            setShowProgress(false)
            setShowErrors(true)
            setJobCompleted(false)
            setStatusText("Server Error: Job ID not found!")
          })
      }, 1000)
      return () => clearInterval(interval)
    }
    // job id set, check for job status
  }, [jobID])

  // TODO EXTRA: keep upload history and show as list while in session

  const handleInputChange = (newValue) => {
    setDBTag(newValue.value)
  }

  function transformArrayToTableData(array) {
    let tableData = []
    array.forEach((item) => {
      let row = {
        row_data: {
          ...item,
        },
        is_selected: false,
      }
      tableData.push(row)
    })
    return tableData
  }

  // Table constants
  const buildingsTableHeaders = [
    {
      header_name: "Building ID",
      header_key: "BuildingId",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    {
      header_name: "Building Address",
      header_key: "BuildingAddress",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
    {
      header_name: "Building Name",
      header_key: "BuildingName",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
  ]

  const errorTableHeaders = [
    {
      header_name: "Building Address",
      header_key: "building_address",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    {
      header_name: "File Name",
      header_key: "file_name",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
    {
      header_name: "Warnings",
      header_key: "warnings",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
    {
      header_name: "Errors",
      header_key: "errors",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
  ]

  return (
    <div className='mb-16'>
      <div className='title'>
        <h1 className='section_heading_1'>Import Buildings</h1>
      </div>
      <div className='hr_container'>
        <hr className='hr_breaker' />
      </div>
      <div className='import_container'>
        <div className='instruction_container'>
          <InstructionalText
            type='Main Instructions'
            title='Upload formatted excel files to import building data for various reports.'
            content={
              <>
                Select formatted excel files for uploading to import building
                data into SISA's database and generate reports.
              </>
            }
            additionalContent={[
              "Unsupported files will be rejected and ignored during the uploading process.",
            ]}
          />
          <InstructionalText
            type='Instructional Text'
            content={
              <>
                When the files are uploaded, the server will start processing
                the files and when completed, any warnings or errors will
                prevent the existing building data from being overwritten.
              </>
            }
            additionalContent={[
              " The report data model is automatically refreshed every hour during the business day starting with the first update of the day at 9AM and at every hour until 4PM PST.",
            ]}
          />
          <InstructionalText
            type='Additional Instructions'
            content={
              <>
                Please note that the report data model needs to be
                <span className='inline_text--bold'> refreshed </span>
                to populate the report with the updated data.
              </>
            }
          />
        </div>

        <Select
          label={"DB Tag"}
          name='db_tag'
          options={db_tags}
          value={db_tag}
          onChange={handleInputChange}
          placeholder='Select DB Tag'
          className='default'
          required
        />

        <div className='form-title-button__container'>
          <Button
            type='button'
            className='button  button__large button__large--secondary'
            buttonText='Select Files'
            onClick={() => file_uploader_modal.current.showModal()}
          />
        </div>

        <FileDetailsTable
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "uploaded_date",
            "status",
            "download",
            "delete",
          ]}
          data_list={sessionFilesList}
          set_data_list={setSessionFilesList}
          fn_remove_row={removeFileFromListHandler}
          fn_download_file={handleOnDownloadFile}
        />

        <FileUploaderModal
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "message",
            "status",
            "delete",
          ]}
          modal_header='Upload Building Assessments'
          content_title='Select spreadsheet template files.'
          content_description='You can store up to 100MB in our secure cloud servers. The maximum file size supported is 5MB.
        This tool supports the following file types: Formatted Excel spreadsheets (.xlsx).'
          modal_ref={file_uploader_modal}
          uploaded_tableData={sessionFilesList}
          fn_remove_row={removeFileFromListHandler}
          set_uploaded_tableData={setSessionFilesList}
          user_data={userData}
          accepted_file_types={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ]}
          fn_upload_handler={selectFilehandler}
        />

        <Button
          type='button'
          className='button  button__large button__large--primary'
          buttonText='Upload'
          onClick={startImportJobHandler}
        />

        {showProgress && (
          <div>
            <p>
              Processing attachment(s) : {parseFloatAndFormat(progressValue)} %
            </p>
            <LinearProgress variant='determinate' value={progressValue} />
          </div>
        )}

        {
          // show errors
          showErrors && (
            <div
              className={
                "text_with_icon " +
                (jobCompleted ? "status_text--complete" : "status_text--error")
              }
            >
              {jobCompleted ? (
                <FontAwesomeIcon icon='fa-light fa-circle-check' />
              ) : (
                <FontAwesomeIcon icon='fa-light fa-circle-exclamation' />
              )}
              {statusText}
            </div>
          )
        }

        {
          // show processed buildings
          processedBuildingsList.length > 0 && (
            <>
              <p className='processed_buildings_table--title'>
                Processed Buildings ({processedBuildingsList.length})
              </p>
              <div className='processed_buildings_table'>
                <Table
                  headers_array={buildingsTableHeaders}
                  data_array={processedBuildingsList}
                  table_entity_name={"Building"}
                />
              </div>
            </>
          )
        }

        {
          // show processed buildings
          errorBuildingsList.length > 0 && (
            <>
              <p className='error_buildings_table--title'>
                Warnings and Errors ({errorBuildingsList.length})
              </p>
              <div className='error_buildings_table'>
                <Table
                  headers_array={errorTableHeaders}
                  data_array={errorBuildingsList}
                  table_entity_name={"Building"}
                />
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}
export default ImportBuildings
